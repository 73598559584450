import Image from 'next/image';
import format from '@/utils/format';
import { DATETIME } from '@/constants/dateTime';
import Link from 'next/link';
import { IPostCardProps } from '@/interfaces/card';

interface BigThumbnailPostCardProps extends IPostCardProps {
  horizontal?: boolean;
  notAtBeautifulHouseTopSection?: boolean;
}

const BigThumbnailPostCard = ({
  category,
  description,
  id,
  publishedAt,
  slug,
  thumbnail,
  title,
  horizontal,
  notAtBeautifulHouseTopSection = true,
}: BigThumbnailPostCardProps) => {
  return (
    <>
      {id ? (
        <Link
          style={{
            animation: 'animate-zoomin 0.5s',
          }}
          href={`/${category?.slug}/${slug}-${id}`}
          className={`w-full group flex ${
            horizontal ? 'flex-row' : 'flex-col'
          } gap-2 lg:gap-4`}
        >
          <div
            className={` ${
              horizontal
                ? 'h-[130px] w-[200px] lg:h-[163px] lg:w-[260px]'
                : notAtBeautifulHouseTopSection
                  ? 'h-[235px]'
                  : 'h-[200px]'
            }  relative`}
          >
            <Image
              src={thumbnail || ''}
              alt={title ?? 'tin hay nha dat'}
              quality={70}
              style={{ objectFit: 'cover' }}
              fill
              sizes="(max-width: 768px) 50vw, (max-width: 1200px) 25vw, 50vw" // className="object-cover"
            />
          </div>

          <div
            className={`flex-1 info ${
              !notAtBeautifulHouseTopSection ? 'px-2' : ''
            }`}
          >
            <p className="text-base font-bold text-DarkBlue uppercase ">
              {category?.name}
            </p>

            <h2 className="mt-[4px] font-medium line-clamp-2 break-words group-hover:text-primary">
              {title}
            </h2>

            <p className={`text-xs font-normal text-DarkBlue`}>
              {format.dateTime(publishedAt || '', DATETIME.FUll)}
            </p>

            {notAtBeautifulHouseTopSection && (
              <p className="mt-[2px] text-sm font-thin line-clamp-2 lg:line-clamp-3 break-words">
                {description}
              </p>
            )}
          </div>
        </Link>
      ) : (
        <></>
      )}
    </>
  );
};

export default BigThumbnailPostCard;
